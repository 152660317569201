import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BlockchainProvidersService } from '@services/providers/blockchain-providers.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, TranslateModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {


  constructor(
    private translateService: TranslateService,
    private blockchainService: BlockchainProvidersService
  ) { }

  ngOnInit(): void {
    this.setupLanguages();

    this.blockchainService.setChainId(environment.activeChainId);
  }

  private setupLanguages() {
    const languages = ['en'];

    this.translateService.addLangs(languages);
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');

    // let language = 'en';

    // const langFromCookies = this.getCookie('site-language');

    // if (langFromCookies && languages.includes(langFromCookies)) {
    //   language = langFromCookies;
    // }
    // else {
    //   const navigatorLang = navigator.languages.find(item => languages.includes(item));

    //   if (navigatorLang) {
    //     language = navigatorLang;
    //   }
    // }

    // this.translateService.use(language);

    // this.translateService.onLangChange.subscribe(({ lang }) => {

    //   document.cookie = `site-language=${lang};expires=${new Date(Date.now() + 999999999999)};path=/;`

    //   if (lang !== language) {
    //     location.reload();
    //   }
    // });
  }

  // private getCookie(name: string) {
  //   let ca: Array<string> = document.cookie.split(';');

  //   let caLen: number = ca.length;
  //   let cookieName = `${name}=`;
  //   let c: string;

  //   for (let i: number = 0; i < caLen; i += 1) {
  //     c = ca[i].replace(/^\s+/g, '');
  //     if (c.indexOf(cookieName) == 0) {
  //       return c.substring(cookieName.length, c.length);
  //     }
  //   }
  //   return '';
  // }
}

// deposits white line
// x by days
// y by date deposit start timestamp end timestamp + staking period

// users
